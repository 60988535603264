import React from "react"
import { Card } from "../../../../../components/layout/card/Card"
import { formatDate } from "../../../../../helper/date"
import { DataRepportApi } from "../type"

export type ReportOrderType = { command: DataRepportApi["command"] }

export const ReportOrder = ({ command }: ReportOrderType) => <Card
	title={`Commande ${command.number}`}
	className="col-m-11 p-3"
>
	<div className="row">
		<div className="col-12 col-media-12 m-b m-media-reset">
			<div className="row">
				<div className="col-6 col-media-4">
					<span className="bold">Date :</span>
				</div>
				<div className="col-6 col-media-6">
					<span>{formatDate(command.date)}</span>
				</div>
			</div>
		</div>
		<div className="col-12 col-media-12 m-b m-media-reset">
			<div className="row">
				<div className="col-6 col-media-4">
					<span className="bold">Véhicule :</span>
				</div>
				<div className="col-6 col-media-6">
					<span>{command.vehicleType}</span>
				</div>
			</div>
		</div>
		<div className="col-12 col-media-12 m-b m-media-reset">
			<div className="row">
				<div className="col-6 col-media-4">
					<span className="bold">Offre :</span>
				</div>
				<div className="col-6 col-media-6">
					<span>{command.type}</span>
				</div>
			</div>
		</div>
		{command.pack && <div className="col-12 col-media-12 m-b m-media-reset">
			<div className="row">
				<div className="col-6 col-media-4">
					<span className="bold">Forfait :</span>
				</div>
				<div className="col-6 col-media-6">
					<span>{command.pack}</span>
				</div>
			</div>
		</div>}
	</div>
	{command.services && command.services.length > 0 && <div className="col-12 col-media-12 m-b m-media-reset">
		<div className="row">
			<div className="col-6 col-media-4">
				<span className="bold inline">{ command.services.length > 1 ? "Options sélectionnés" : "Option sélectionné"}  :</span>
			</div>
			<div className="col-6 col-media-6">
				<ul className="m-reset">
					{command.services.map((elements, index) => <li style={{ paddingBottom: 5 }} key={index}>{elements}</li>)}
				</ul>
			</div>
		</div>
	</div>}
	{command.comments && <div className="col-12 col-media-12 m-b m-media-reset">
		<div className="row">
			<div className="col-6 col-media-4">
				<span className="bold">Commentaire :</span>
			</div>
			<div className="col-6 col-media-6">
				<span>{command.comments}</span>
			</div>
		</div>
	</div>}
</Card>
