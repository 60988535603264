import React from "react"
import ReactDOM from "react-dom/client"
import "./output.css"
import reportWebVitals from "./reportWebVitals"
import AppRouter from "./AppRouter"
import { AuthProvider } from "./service/api/authContext"

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
)
root.render(
	<AuthProvider>
		<AppRouter/>
	</AuthProvider>
)

// If you want to start measuring performance in your app, pass a function
// To log results (for example: reportWebVitals(console.log))
// Or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
