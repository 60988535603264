import React, { useRef, useState } from "react"
import { Icon } from "../../text/icon/icon"
import { useTranslation } from "react-i18next"
import SelectMenu from "../select/content/SelectMenu"
import { UseFormRegisterReturn } from "react-hook-form"

type LocationProps = {
	type?: "primary" | "neutral"
	register?: UseFormRegisterReturn<any>
	placeholder?: string
	input?: {
		value?: string
		className?: string
	}
	setAddress: (address: GeolocApiTye) => void
	className?: string
}

export type GeolocApiTye = {
	address?: string
	geometry?: {
		coordinates: number[]
		type: string
	}
	properties?: {
		streetNumber: string
		locality: string
		postalCode: string
		streetName: string
	}
}

const Location = ({ setAddress, className, placeholder = "", type = "primary", input, register }: LocationProps) => {
	const { t } = useTranslation()
	const [ showSuggestion, setShowSuggestion ] = useState(false)
	const [ geolocData, setGelocData ] = useState<{ [address: string]: GeolocApiTye }>({})
	const [inputValue, setInputValue] = useState<string>(input?.value ? input.value : "")
	const locationRef = useRef<HTMLDivElement | null>(null)
	const [debounceTimeoutId, setDebounceTimeoutId] = useState<NodeJS.Timeout | null>(null)

	const retrieveAddress = (value: string) => {
		if(!value || value.length <= 0) {
			return
		}
		const param = new URLSearchParams({ q: value })

		// Annuler le précédent délai si l'utilisateur tape encore du texte
		if(debounceTimeoutId) {
			clearTimeout(debounceTimeoutId)
		}

		// Définir un nouveau délai pour l'appel à l'API
		const newTimeoutId = setTimeout(async() => {
			const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/geocode/search?${param.toString()}`, {
				method: "GET"
			})
			if(response.ok) {
				const data = await response.json()
				const geolocDataReponse: { [addr: string]: any } = {}
				data.forEach((item: GeolocApiTye) => {
					let addressKey = ""
					if(item.properties!.streetNumber) {
						addressKey += item.properties!.streetNumber
					}
					if(item.properties!.streetName) {
						addressKey += ` ${item.properties!.streetName}`
					}
					if(item.properties!.postalCode) {
						addressKey += ` ${item.properties!.postalCode}`
					}
					if(item.properties!.locality) {
						addressKey += ` ${item.properties!.locality}`
					}
					geolocDataReponse[addressKey] = { ...item, address: addressKey }
				})
				setGelocData(geolocDataReponse)
				setShowSuggestion(true)
			}
		}, 1000) // Attendre 1.5 secondes après que l'utilisateur ait arrêté de taper

		setDebounceTimeoutId(newTimeoutId)
	}

	if(type === "neutral") {
		return <div ref={locationRef} className="w-full">
			<input
				className={`focus:outline-none ${input?.className ? input.className : ""}`}
				onChange={(e) => {
					setInputValue(e.target.value)
					retrieveAddress(e.target.value)
				}}
				{...register}
				placeholder={t(placeholder)}
				value={inputValue}
				type="text"
			/>
			<SelectMenu
				setIsOpen={setShowSuggestion}
				inputRef={locationRef}
				isOpen={showSuggestion}
				clickOutside={() => {
					if(Object.keys(geolocData).indexOf(inputValue) === -1) {
						setAddress({})
						setInputValue("")
					}
				}}
				values={Object.keys(geolocData)}
				currentValue={[inputValue!]}
				onChange={(value) => {
					setShowSuggestion(false)
					setInputValue(value)
					if(!geolocData[value]) {
						setAddress({})
					}
					setAddress(geolocData[value])
				}}
				canBeNull={false}
			/>
		</div>
	}

	return <div ref={locationRef} className={`${className} location p-1 bg-neutralLight radius-common flex w-full`}>
		<input
			className={`w-full pl-5 text-c-neutral bg-neutralLight text-base font-normal radius-common focus:outline-none ${input?.className}`}
			onChange={(e) => {
				setInputValue(e.target.value)
				retrieveAddress(e.target.value)
			}}
			placeholder={t(placeholder)}
			value={inputValue}
			type="text"
		/>
		<Icon className="self-center mr-1" type="location" size="medium" />
		<SelectMenu
			setIsOpen={setShowSuggestion}
			inputRef={locationRef}
			className="mt-10"
			isOpen={showSuggestion}
			clickOutside={() => {
				if(Object.keys(geolocData).indexOf(inputValue) === -1) {
					setAddress({})
					setInputValue("")
				}
			}}
			values={Object.keys(geolocData)}
			currentValue={[inputValue!]}
			onChange={(value) => {
				setShowSuggestion(false)
				setInputValue(value)
				if(!geolocData[value]) {
					setAddress({})
				}
				setAddress(geolocData[value])
			}}
			canBeNull={false}
		/>
	</div>
}

export default Location
