
import Here from "@here/maps-api-for-javascript"

export type RouteType = {lat: number, lng: number, type: number, step: string}
export type CoordinatesType = {lat: number, lng: number}

/**
 * Permet de créer les marqueurs sur la carte
 * @param pointList liste des points avec leurs coordonnées et leurs type
 * @returns Here.map.Marker
 */
export const createMarker = (pointList: RouteType[]): Here.map.Marker[] => pointList.map((coord) => {
	let svg = '<svg height="24" xmlns="http://www.w3.org/2000/svg" '
	switch(coord.type) {
		case 2: // Warning
			svg += `width="24"><circle stroke="orange" fill="orange" cx="50%" cy="55%" r="10"
			height="22" /><text x="12" y="18" font-size="10pt"
			font-family="Arial" font-weight="bold" text-anchor="middle"
				>!</text>`
			break
		case 1:
		default:
			svg += `width="${4.5 * coord.step.length + 20}"><ellipse stroke="orange" fill="white" cx="50%" cy="55%" rx="${coord.step.length + 8}pt" ry="10" />
			<text x="50%" y="55%" font-size="10pt" font-family="Arial" font-weight="bold" text-anchor="middle" alignment-baseline="middle" >${coord.step}</text>`
			break
	}

	svg += "</svg>"
	return new Here.map.Marker(coord, {
		icon: new Here.map.Icon(svg),
		volatility: true,
	} as Here.map.Marker.Options)
})

/**
 * Permet de calculer et générer un tracer sur la carte
 *
 * @param platform platforme here
 * @param map map here
 * @param points liste des points
 * @param reset default false, permet de réinitialier la carte
 */
export const calculateRoute = (platform: Here.service.Platform, map: Here.Map, points: RouteType[], reset = false) => {
	if(reset) {
		map.removeObjects(map.getObjects())
	}

	const routeResponseHandler = (response: any) => {
		const sections = response.routes[0].sections
		const lineStrings: any[] = []
		sections.forEach((section: any) => {
			// convert Flexible Polyline encoded string to geometry
			lineStrings.push(Here.geo.LineString.fromFlexiblePolyline(section.polyline))
		})
		const multiLineString = new Here.geo.MultiLineString(lineStrings)

		// Create the polyline for the route
		const routePolyline = new Here.map.Polyline(multiLineString, {
			style: {
				lineWidth: 5
			}
		} as any)

		// Add the polyline to the map
		map.addObject(routePolyline)
		map.addObjects(createMarker(points))
	}

	// Get an instance of the H.service.RoutingService8 service
	const router = platform.getRoutingService(undefined, 8)

	points.forEach((currPoint, index) => {
		if(index === 0) {
			return
		}
		const prevPoint = points[index - 1]
		// Define the routing service parameters
		const routingParams = {
			"origin": `${prevPoint.lat},${prevPoint.lng}`,
			"destination": `${currPoint.lat},${currPoint.lng}`,
			"transportMode": "car",
			"return": "polyline"
		}
		//// Call the routing service with the defined parameters
		router.calculateRoute(routingParams, routeResponseHandler, console.error)
	})
}

export const calculateCenter = (points: CoordinatesType[]): CoordinatesType => {
	if(points.length === 0) {
		return { lat: 43.531755388861825, lng: 5.430983958810115 }
		// Todo feature Renvoyer éventuellement les coordonnées de l'entreprise appartenant a l'utilisateur qui consulte une carte
	} else if( points.length === 1) {
		return points[0]
	}

	let totalLat = 0
	let totalLng = 0

	for(const coordinate of points) {
		totalLat += coordinate.lat
		totalLng += coordinate.lng
	}

	const centralLat = totalLat / points.length
	const centralLng = totalLng / points.length

	return { lat: centralLat, lng: centralLng }
}
