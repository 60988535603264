import React from "react"
import logo from "../../logo.svg"

type props = { height: number, width?: number}

const GifLoader = ({ height, width }: props) => <img
	src={logo}
	title="Truck2you loader"
	className="img-fluid"
	style={{ height, width }}
/>

export default GifLoader
