import React, { useContext, useEffect, useState } from "react"
import { ReservationInformationType, UtilityStepEnum } from "../UtilityCustom"
import { Button } from "../../../../components/button/Button"
import { SubmitHandler, useForm } from "react-hook-form"
import { Text } from "../../../../components/text/Text"
import Switch from "../../../../components/input/switch/Switch"
import InputNumber from "../../../../components/input/number/Number"
import { useTranslation } from "react-i18next"
import { authContext } from "../../../../service/api/authContext"

type HandlingProps = {
	setFormStep: (value: UtilityStepEnum) => void
	handlingInfo: {
		handler?: string
		floor: number
	}
	reservationInformation: {
		data: ReservationInformationType
		set: React.Dispatch<React.SetStateAction<ReservationInformationType>>
	}
	priceLoader: {
		active: boolean
		setActive: React.Dispatch<React.SetStateAction<boolean>>
	}
}

type PriceResponse = {
	price: number
}

const Handling = ({ setFormStep, handlingInfo, reservationInformation, priceLoader }: HandlingProps) => {
	const { t } = useTranslation("utilityCustom")
	const { token } = useContext(authContext)
	const { register, handleSubmit, watch, reset, setValue } = useForm<HandlingProps["handlingInfo"]>({
		defaultValues: handlingInfo
	})
	const handling = watch("handler")
	const floor = watch("floor")
	const [initialDisplay, setInitialDisplay] = useState(true)

	useEffect(() => {
		// Met a jour la manut et les étages en mémoir
		if(initialDisplay && (handling !== "undefined" || floor > 0)) {
			setInitialDisplay(false)
			updateOrder(Number(handling), floor)
		}
	}, [])


	const onSubmitHandling: SubmitHandler<HandlingProps["handlingInfo"]> = (data) => {
		reservationInformation.set((prevState) => ({
			...prevState,
			handler: data.handler,
			floor: data.floor
		}))
		setFormStep(UtilityStepEnum.ADDITIONAL_INFO)
	}

	const updateOrder = (newHandling: number, newFloor: number) => {
		const fetchData = async() => {
			priceLoader.setActive(true)
			try {
				const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/api/search/utility/update-price`, {
					method: "POST",
					headers: {
						Authorization: `Bearer ${token}`
					},
					body: JSON.stringify({
						searchHistory: reservationInformation.data.searchHistory,
						handling: newHandling,
						floor: newFloor
					})
				})
				if(response.ok) {
					priceLoader.setActive(false)
					const priceResponse: PriceResponse = await response.json()
					reservationInformation.set((prevState) => ({
						...prevState,
						price: priceResponse.price
					}))
				}
			} catch (error) {
				console.log("Erreur lors de la récupération des données de l'API", error)
				// todo gestion de l'erreur
			}
		}
		fetchData()
	}

	const handleChangeHandler = (value: string) => {
		setValue("handler", value)
		if(Number(value) === 0) {
			setValue("floor", 0)
			setFloorQuestion(false)
		}
		updateOrder(Number(value), floor)
	}

	const handleChangeFloor = (value: number) => {
		setValue("floor", value) // Met à jour la valeur du champ "floor"
		updateOrder(Number(handling), value)
	}

	const handleFloorQuestion = (val: boolean) => {
		if(val) {
			reset((formValues) => ({
				...formValues,
				floor: 0
			}))
			setValue("floor", 0)
			updateOrder(Number(handling), 0)
		}
		setFloorQuestion(!val)
	}
	const [floorQuestion, setFloorQuestion] = React.useState<boolean>(false)

	useEffect(() => {
		if(handlingInfo.floor && handlingInfo.floor > 0) {
			setFloorQuestion(true)
		}
	}, [])

	const handlingContent: { text: string, subText: string, picture: string, value: string }[] = [
		{
			text: "form.handling.worker",
			subText: "form.handling.workerLimit.limit",
			picture: "https://res.cloudinary.com/dl2d3weuy/image/upload/v1727337238/Pictogrammes%20appli/Manutention/sans-manutention.png",
			value: "0"
		},
		{
			text: "form.handling.worker",
			subText: "form.handling.workerLimit.limit",
			picture: "https://res.cloudinary.com/dl2d3weuy/image/upload/v1727337238/Pictogrammes%20appli/Manutention/1-manutentionnaire.png",
			value: "1"
		},
		{
			text: "form.handling.worker",
			subText: "form.handling.workerLimit.limit",
			picture: "https://res.cloudinary.com/dl2d3weuy/image/upload/v1727337238/Pictogrammes%20appli/Manutention/2-manutentionnaires.png",
			value: "2"
		}
	]

	return <div className="p-5">
		<form onSubmit={handleSubmit(onSubmitHandling)}>
			<div className="mb-5">
				<Text size="large">{t("form.handling.title")}</Text>
			</div>
			<div className="flex space-around mb-5">
				{handlingContent.map((handlCnt, index) => <div key={index} className="flex flex-col justify-center space-between">
					<div className={`w-fit h-fit ${Number(handling) === index ? "border-c-primary" : "border-c-neutralLight"} border-2 mr-5`}>
						<label>
							<input
								type="radio"
								hidden
								value={String(index)}
								{...register("handler")}
								onChange={(event) => handleChangeHandler(event.target.value)}
							/>
							<img
								className="max-w-60 max-h-60"
								src={handlCnt.picture}
								alt={t(handlCnt.text, { count: index })}
							/>
						</label>
					</div>
					<Text className="flex self-center" size="medium">{t(handlCnt.text, { count: index })}</Text>
					<Text className="flex self-center text-c-neutral" size="small">{t(handlCnt.subText, { count: index })}</Text>
				</div>)}
			</div>
			{(handling === "1" || handling === "2") && <div className="flex flex-col mb-5">
				<Text size="big" className="pb-2">{t("form.handling.floor.title")}</Text>
				<div className="flex flex-col">
					<div className="flex w-full">
						<div className="flex flex-col space-between">
							<div>
								<div className="lg:w-40">
									<Switch
										buttonState={!floorQuestion}
										setButtonState={handleFloorQuestion}
										leftText="non"
										rightText="oui"
									/>
								</div>
							</div>
						</div>
						{floorQuestion && <div className="flex flex-row w-full space-between">
							<div className="flex justify-center ml-6">
								<Text size="base" className="text-c-neutral self-center">{t("form.handling.floor.question")}</Text>
								<div className="w-24 ml-2">
									<InputNumber range={{ min: 0, max: 6 }} register={register("floor")} onChange={handleChangeFloor}/>
								</div>
							</div>
						</div>}
					</div>
				</div>
			</div>}
			<div className="flex justify-center">
				<div className="flex w-1/3">
					<Button text={t("form.handling.continue")} type="primary" btnType="submit"/>
				</div>
			</div>
		</form>
	</div>
}

export default Handling
