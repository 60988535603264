/* eslint-disable */
import React, { useContext, useEffect, useState } from "react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"

import Admin from "./pages/litle/Admin/Admin"
import NotFound from "./pages/NotFound"
import Report from "./pages/litle/Mission/Report/Report"
import Offer from "./pages/litle/Offer/Offer"
import { authContext } from "./service/api/authContext"
import { checkAuthentication } from "./service/api/api"
import "./i18n/config"
import Provision from "./pages/litle/Provision/Provision"
import "moment/locale/fr"
import HeavyCustom from "./pages/Custom/Heavy/HeavyCustom"
import UtilityCustom from "./pages/Custom/Utility/UtilityCustom"
import GraphicDemo from "./pages/Demo/Graphic/GraphicDemo"
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";

const AppRouter = (): React.JSX.Element => {
	const [ allowed, setAllowed ] = useState<boolean>(false)
	const [ inIframe, setInIframe ] = useState<boolean>(false)
	const { token, login, grantAuth } = useContext(authContext)

	useEffect(() => {
		const handleMessage = async (event?: MessageEvent) => {

			// Vérifie l'origine du message pour des raisons de sécurité
			if (event && event.origin === process.env.REACT_APP_TRUST_DOMAIN) {
				setInIframe(true) // Indique que le composant est dans un iframe

				// Vérifie que le token a bien été reçu
				if (typeof event.data?.token === "string") {
					//console.log("Received token:", event.data.token)

					// Vérifie l'authentification avec le token reçu
					try {
						const response = await checkAuthentication(event.data.token)
						//console.log("Authentication response:", response)

						if (response.isAuthenticated) {
							login(event.data.token, response.user) // Mets à jour le contexte d'authentification
							grantAuth(response.isAdmin, response.isBillingMonthly)
							setAllowed(true) // Autorise l'accès
						} else {
							setAllowed(false)
						}
					} catch (error) {
						console.error("Authentication error:", error)
						setAllowed(false) // Si une erreur survient, interdire l'accès
					}
				} else {
					setAllowed(false) // Si aucun token valide n'est trouvé
				}
			} else {
				// Gère le cas où l'iframe est ouvert directement sans token via postMessage
				const storedToken = localStorage.getItem("token")
				if (storedToken) {
					try {
						const response = await checkAuthentication(storedToken)
						if (response.isAuthenticated) {
							login(storedToken, response.user) // Mets à jour le contexte d'authentification
							grantAuth(response.isAdmin, response.isBillingMonthly)
							setAllowed(true) // Autorise l'accès
						} else {
							localStorage.removeItem("token") // Supprime le token invalide
							setAllowed(false)
						}
					} catch (error) {
						console.error("Authentication error:", error)
						setAllowed(false)
					}
				} else {
					setAllowed(false) // Pas de token dans le localStorage
				}
			}
		}

		if (window.self !== window.top) {
			// Ajoute un listener pour le message
			window.addEventListener("message", handleMessage)

			// Nettoie l'événement lorsqu'il n'est plus nécessaire
			return () => {
				window.removeEventListener("message", handleMessage)
			};
		} else {
			handleMessage()
		}
	}, [])

	document.documentElement.style.setProperty("--primary", "#ffc32d")
	document.documentElement.style.setProperty("--primaryDark", "#d4a124")
	document.documentElement.style.setProperty("--white", "#ffffff")
	document.documentElement.style.setProperty("--whiteRGB", "255, 255, 255")
	document.documentElement.style.setProperty("--black", "#000000")
	document.documentElement.style.setProperty("--third", "#111328")
	document.documentElement.style.setProperty("--second", "#1e2837")
	document.documentElement.style.setProperty("--neutral", "#525f7f")
	document.documentElement.style.setProperty("--neutralLight", "#e7e7f1")
	document.documentElement.style.setProperty("--none", "#ced4da")
	document.documentElement.style.setProperty("--valid", "#1aae6f")
	document.documentElement.style.setProperty("--validLight", "#b0eed3")
	document.documentElement.style.setProperty("--warning", "#F99716")
	document.documentElement.style.setProperty("--warningRGB", "249,151,22")

	return <>
		{/* todo optimiser sont emplacement plus tard */}
		{!inIframe && <Header/>}
		<Router>
			<Routes>
				<Route path="/offer" element={<Offer/>}/>
				<Route path="/provision" element={<Provision/>}/>
				<Route path="/utility-custom" element={<UtilityCustom/>}/>
				<Route path="/heavy-custom" element={<HeavyCustom/>}/>
				{/*<Route path="/graphicChart" element={<GraphicDemo/>}/>*/}
				{allowed && <Route path="/admin" element={<Admin/>}/>}
				{allowed && <Route path="/mission/:id/report" element={<Report/>}/>}
				<Route path="*" element={<NotFound/>}/>
			</Routes>
		</Router>
	</>
}

export default AppRouter
